import axios from '../../utils/axiosWrapper'
import environment from '../../environment'
import { selectUnit } from './units'
import { selectUserGroup } from './groupAndStaffSlotMetaData'
import { fetchStaffPool } from './staffPool'
import ErrorCheck from '../../utils/errorCheck'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

const serverUrl = environment._serverUrl

export const authenticateUser =
  ({ user34, authToken, preferredSiteId }) =>
  (dispatch) => {
    const endpointForAuthUser = serverUrl + `User/GetUserByHca34/${user34}`

    // Make a request for a user with a given ID
    if (!(authToken && user34))
      dispatch({ type: 'user/FETCH_AUTHENTICATE_USER' })
    axios({
      method: 'get',
      url: endpointForAuthUser,
      headers: {
        authorization: authToken,
        siteId: preferredSiteId,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch({
          type: 'user/FETCH_AUTHENTICATE_USER_RECEIVED',
          payload: response.data,
        })
        dispatch(getUserPermissions(authToken))
      })
      .catch(function (error) {
        ErrorCheck(error)
        dispatch({
          type: 'user/FETCH_AUTHENTICATE_USER_ERROR',
          payload: error.response,
        })
      })
  }

export const getUserPermissions = (authToken) => (dispatch) => {
  const endpointForAuthUser = serverUrl + `Metadata/AuthRolePermissions`

  dispatch({ type: 'user/FETCH_USER_PERMISSIONS' })
  axios({
    method: 'get',
    url: endpointForAuthUser,
    headers: {
      authorization: authToken,
      Pragma: 'no-cache',
    },
  })
    .then(function (response) {
      dispatch({
        type: 'user/FETCH_USER_PERMISSIONS_RECEIVED',
        payload: response.data,
      })
    })
    .catch(function (error) {
      ErrorCheck(error)
      dispatch({
        type: 'user/FETCH_USER_PERMISSIONS_ERROR',
        payload: error.response,
      })
    })
}
export const applicationStatus = (authToken) => (dispatch) => {
  const endpointForAuthUser = serverUrl + `metadata/status/`

  dispatch({ type: 'metadata/FETCH_APPLICATION_STATUS' })
  axios({
    method: 'get',
    url: endpointForAuthUser,
    headers: {
      authorization: authToken,
      Pragma: 'no-cache',
    },
  })
    .then(function (response) {
      dispatch({
        type: 'metadata/FETCH_APPLICATION_STATUS_RECEIVED',
        payload: response.data,
      })
    })
    .catch(function (error) {
      dispatch({
        type: 'metadata/FETCH_APPLICATION_STATUS_ERROR',
        payload: error.response,
      })
    })
}

export const managePageAuthenticateUser =
  ({ user34, authToken, siteId }) =>
  (dispatch) => {
    const endpointForAuthUser = serverUrl + `User/GetUserByHca34/${user34}`

    // Make a request for a user with a given ID
    dispatch({ type: 'user/FETCH_MANAGE_PAGE_AUTHENTICATE_USER' })
    axios({
      method: 'get',
      url: endpointForAuthUser,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch({
          type: 'user/FETCH_MANAGE_PAGE_AUTHENTICATE_USER_RECEIVED',
          payload: response.data,
        })
      })
      .catch(function (error) {
        ErrorCheck(error)
        dispatch({
          type: 'user/FETCH_MANAGE_PAGE_AUTHENTICATE_USER_ERROR',
          payload: error.response,
        })
      })
  }

export const saveUserToken = (authToken) => (dispatch) => {
  dispatch({
    type: 'user/SAVE_USER_TOKEN',
    payload: authToken,
  })
}

export const saveUser34 = (user34) => (dispatch) => {
  dispatch({
    type: 'user/SAVE_USER_34',
    payload: user34,
  })
}

export const getGlobalUserSettings =
  ({ authToken, sites }) =>
  (dispatch) => {
    const endpointForAuthUser = serverUrl + `User/GlobalSettings`
    dispatch({ type: 'user/FETCHING_GLOBAL_USER_SETTINGS', payload: true })
    axios({
      method: 'get',
      url: endpointForAuthUser,
      headers: {
        authorization: authToken,
        Pragma: 'no-cache',
      },
    })
      .then((response) => {
        let data = response.data
        let selectedSiteId
        dispatch({
          type: 'user/FETCH_GLOBAL_USER_SETTINGS_SUCCESS',
          payload: data,
        })

        if (
          !data.preferredSiteId ||
          !sites.some((site) => site.siteId === data.preferredSiteId)
        ) {
          selectedSiteId = sites[0].siteId
        } else {
          selectedSiteId = data.preferredSiteId
        }
        const facilityCoid = sites.find(
          (site) => site.siteId === selectedSiteId,
        ).facilityCoid

        dispatch(fetchStaffPool(authToken, selectedSiteId))
        const newUserSettingsObject = {
          ...data,
          preferredFacilityId: facilityCoid,
          preferredSiteId: selectedSiteId,
        }

        dispatch(
          setGlobalUserSettings({
            authToken,
            globalSettings: newUserSettingsObject,
          }),
        )
        dispatch(
          userSettings({
            siteId: selectedSiteId,
            authToken,
          }),
        )
        dispatch({
          type: 'sites/SET_SELECTED_SITE',
          payload: selectedSiteId,
        })

        dispatch({
          type: 'user/FETCH_GLOBAL_USER_SETTINGS_SUCCESS',
          payload: data,
        })
        fetchUnitsInternal(authToken, selectedSiteId)

        function fetchUnitsInternal(authToken, selectedSiteId) {
          const getUnitsUrl =
            serverUrl + `Location/GetUnitsBySite?siteId=${selectedSiteId}`

          dispatch({ type: 'assignments/FETCH_UNITS' })
          axios({
            method: 'get',
            url: getUnitsUrl,
            headers: {
              authorization: authToken,
              siteId: selectedSiteId,
              Pragma: 'no-cache',
            },
          })
            .then(function (response) {
              dispatch({
                type: 'assignments/FETCH_UNITS_RECEIVED',
                payload: response.data,
              })
            })
            .catch(function (error) {
              if (error.response.status === 404) {
                dispatch({
                  type: 'assignments/FETCH_UNITS_RECEIVED',
                  payload: [],
                })
              } else if (error.response.status !== 404) {
                ErrorCheck(error)
                dispatch({
                  type: 'assignments/FETCH_UNITS_ERROR',
                  payload: error.response,
                })
              }
            })
        }
      })
      .catch(function (error) {
        ErrorCheck(error)
        dispatch({ type: 'user/FETCHING_GLOBAL_USER_SETTINGS', payload: false })
        dispatch({
          type: 'user/FETCH_GLOBAL_USER_SETTINGS_ERROR',
          payload: error.response,
        })
      })
  }

export const setGlobalUserSettings =
  ({ authToken, globalSettings }) =>
  (dispatch, getState) => {
    const endpointForAuthUser = serverUrl + `User/GlobalSettings`

    if (globalSettings && globalSettings._etag) delete globalSettings._etag

    axios({
      method: 'put',
      data: {
        ...globalSettings,
        preferredFacilityId:
          getState().sites?.sites?.find(
            (site) => site.siteId === globalSettings.preferredSiteId,
          )?.facilityCoid || '',
      },
      url: endpointForAuthUser,
      headers: {
        authorization: authToken,
        Pragma: 'no-cache',
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        const { data } = response
        dispatch({
          type: 'user/FETCH_GLOBAL_USER_SETTINGS_SUCCESS',
          payload: data,
        })
        dispatch({
          type: 'sites/SET_SELECTED_SITE',
          payload: data.preferredSiteId,
        })
      })
      .catch(function (error) {
        ErrorCheck(error)
        dispatch({
          type: 'user/UPDATE_GLOBAL_USER_SETTINGS_ERROR',
          payload: error.response,
        })
      })
  }

export const userSettings =
  ({ siteId, authToken }) =>
  (dispatch) => {
    const endpointForAuthUser = serverUrl + `User/Settings`
    dispatch({ type: 'user/FETCHING_USER_SETTINGS', payload: true })
    axios({
      method: 'get',
      url: endpointForAuthUser,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then((response) => {
        let data = response.data
        const setSettings = async ({ authorization, siteId }) => {
          try {
            const getUnits = await axios({
              method: 'get',
              url: `${serverUrl}Location/GetUnitsBySite?siteId=${siteId}`,
              headers: {
                authorization,
                siteId,
                Pragma: 'no-cache',
              },
            })

            const units = getUnits.data
            const sortedUnits = _.sortBy(units, (o) => o.displayName)

            const getRoles = await axios({
              method: 'get',
              url: `${serverUrl}Metadata/ViewConfiguration`,
              headers: { authorization, siteId, Pragma: 'no-cache' },
            })
            const roles = getRoles.data

            const updateUnitData = (data, preferredUnitIds) => ({
              ...data,
              preferredUnitIds: preferredUnitIds,
              preferredRoleGroup: data.preferredRoleGroup || (roles && roles[0].id),
            })

            if (!data.preferredUnitIds || data.preferredUnitIds.length === 0) {
              data.preferredUnitIds = [sortedUnits[0].id]
            }

            if (!data.preferredUnitIds) {
              data.preferredUnitId = sortedUnits[0].id
            }

            data = updateUnitData(data, data.preferredUnitIds)

            dispatch({
              type: 'user/UPDATE_USER_SETTINGS',
              payload: data,
            })
            const department = roles.find(
              (role) => role.id === data.preferredRoleGroup,
            )
            dispatch({
              type: 'ui/SET_SELECTED_DEPARTMENT',
              payload: department ? department.roleGroup : null,
            })
            if (!data.preferredUnitIds) {
              dispatch({
                type: 'assignments/SET_SELECTED_UNIT',
                payload: units[1] ? units[1] : units[0],
              })
            }
            dispatch(selectUserGroup(data.preferredRoleGroup))
            dispatch(selectUnit(data.preferredUnitIds))
            if (
              data.preferredUnitIds.includes(
                '00000000-0000-0000-0000-000000000000',
              )
            ) {
              dispatch({
                type: 'user/ALL_UNITS_SELECTED',
                payload: true,
              })
            }
            dispatch({ type: 'user/FETCHING_USER_SETTINGS', payload: false })
          } catch (e) {
            const update = {
              preferredUnitId: [],
              preferredRoleGroup: 'empty',
            }
            data = { ...data, ...update }

            dispatch({
              type: 'user/UPDATE_USER_SETTINGS',
              payload: data,
            })

            dispatch(selectUserGroup(data.preferredRoleGroup))
            if (data.preferredUnitIds) {
              dispatch(selectUnit(data.preferredUnitIds))
            }
            dispatch({ type: 'user/FETCHING_USER_SETTINGS', payload: false })
          }
        }
        setSettings({
          authorization: authToken,
          siteId,
        })
      })
      .catch((error) => {
        ErrorCheck(error)
        dispatch({ type: 'user/FETCHING_USER_SETTINGS', payload: false })
        dispatch({
          type: 'user/FETCH_USER_SETTINGS_ERROR',
          payload: error.response,
        })
      })
  }
export const getAllUsers =
  ({
    facilityId,
    authToken,
    searchText,
    divisionId,
    clinicalRole,
    skip,
    take,
    sortBy,
    sortDirection,
  }) =>
  (dispatch) => {
    const endpointForAuthUser =
      serverUrl +
      `User?${
        divisionId && divisionId !== 'All'
          ? 'divisionId=' + divisionId + '&'
          : ''
      }${facilityId ? 'facilityId=' + facilityId + '&' : ''}${
        clinicalRole ? 'clinicalRole=' + clinicalRole + '&' : ''
      }${searchText !== '' ? 'search=' + searchText + '&' : ''}${
        'skip=' + skip + '&'
      }${take ? 'take=' + take + '&' : ''}${
        sortBy ? 'sortBy=' + sortBy + '&' : ''
      }${sortDirection ? 'sortDirection=' + sortDirection : ''}`
    dispatch({ type: 'user/FETCHING_ALL_USERS' })
    axios({
      method: 'get',
      url: endpointForAuthUser,
      headers: {
        authorization: authToken,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        const filteredUsers = response.data.map((user) => {
          user.authorizationRoles.forEach((role) => {
            if (role._etag) delete role._etag
            return role
          })
          return user
        })
        if (facilityId) {
          dispatch(
            exportUsers({
              facilityId,
              authToken,
              searchText,
              divisionId,
              clinicalRole,
              sortBy,
              sortDirection,
            }),
          )
        }
        dispatch({
          type: 'user/FETCH_USERS_RECEIVED',
          payload: filteredUsers,
        })
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          dispatch(
            exportUsers({
              facilityId,
              authToken,
              searchText,
              divisionId,
              clinicalRole,
              sortBy,
              sortDirection,
            }),
          )
          dispatch({
            type: 'user/FETCH_USERS_RECEIVED',
            payload: [],
          })
          dispatch({
            type: 'user/SET_PAGINATION',
            payload: { skip: skip !== 0 ? skip - 50 : 0, take: take - 50 },
          })
        }

        console.log(error.response)
        ErrorCheck(error)
        dispatch({
          type: 'user/FETCH_USERS_ERROR',
          payload: error.response,
        })
      })
  }
export const exportUsers =
  ({
    facilityId,
    authToken,
    searchText,
    divisionId,
    clinicalRole,
    sortBy,
    sortDirection,
  }) =>
  (dispatch) => {
    const endpointForAuthUser =
      serverUrl +
      `User/Export?${
        divisionId && divisionId !== 'All'
          ? 'divisionId=' + divisionId + '&'
          : ''
      }${facilityId ? 'facilityId=' + facilityId + '&' : ''}${
        clinicalRole ? 'clinicalRole=' + clinicalRole + '&' : ''
      }${
        searchText !== '' && searchText !== null
          ? 'search=' + searchText + '&'
          : ''
      }${sortBy ? 'sortBy=' + sortBy + '&' : ''}${
        sortDirection ? 'sortDirection=' + sortDirection : ''
      }`
    dispatch({ type: 'user/EXPORTING_USERS' })
    axios({
      method: 'get',
      url: endpointForAuthUser,
      headers: {
        authorization: authToken,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch({
          type: 'user/EXPORT_USERS_RECEIVED',
          payload: response.data,
        })
      })
      .catch(function (error) {
        console.log(error.response)
        ErrorCheck(error)
        dispatch({
          type: 'user/EXPORT_USERS_ERROR',
          payload: error.response,
        })
      })
  }

export const setUserSettings =
  ({ siteId, authToken, userSettings }) =>
  (dispatch, getState) => {
    const endpointForAuthUser = serverUrl + `User/Settings`
    if (
      userSettings.preferredUnitIds &&
      userSettings.preferredUnitIds.includes(
        '00000000-0000-0000-0000-000000000000',
      )
    ) {
      dispatch({
        type: 'user/ALL_UNITS_SELECTED',
        payload: true,
      })
      axios({
        method: 'put',
        data: {
          ...userSettings,
          hca34: getState().user.user34,
          siteId,
          facilityCoid: getState().sites.sites.find(
            (site) => site.siteId === siteId,
          ).facilityCoid,
        },
        url: endpointForAuthUser,
        headers: {
          authorization: authToken,
          siteId,
          Pragma: 'no-cache',
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((response) => {
          dispatch({
            type: 'user/UPDATE_USER_SETTINGS',
            payload: response.data,
          })
        })
        .catch(function (error) {
          ErrorCheck(error)
          dispatch({
            type: 'user/UPDATE_USER_SETTINGS_ERROR',
            payload: error.response,
          })
        })
    } else {
      delete userSettings._etag
      axios({
        method: 'put',
        data: {
          ...userSettings,
          hca34: getState().user.user34,
          siteId,
          facilityCoid: getState().sites.sites.find(
            (site) => site.siteId === siteId,
          ).facilityCoid,
        },
        url: endpointForAuthUser,
        headers: {
          authorization: authToken,
          siteId,
          Pragma: 'no-cache',
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((response) => {
          dispatch({
            type: 'user/UPDATE_USER_SETTINGS',
            payload: response.data,
          })
          dispatch({
            type: 'user/ALL_UNITS_SELECTED',
            payload: false,
          })
        })
        .catch(function (error) {
          ErrorCheck(error)
          dispatch({
            type: 'user/UPDATE_USER_SETTINGS_ERROR',
            payload: error.response,
          })
        })
    }
  }

export const toggleShowTutorial = (showTutorial) => ({
  type: 'TOGGLE_SHOW_TUTORIAL',
  payload: showTutorial,
})

export const deleteUser =
  ({
    id,
    authToken,
    siteId,
    ui,
    loggedInUser,
    searchText,
    divisionId,
    clinicalRole,
    skip,
    take,
    sortBy,
    sortDirection,
    selectedAdminSite,
  }) =>
  (dispatch) => {
    const authorization = authToken
    axios({
      method: 'DELETE',
      url: serverUrl + `User/${id}`,
      headers: {
        authorization,
        siteId,
        Pragma: 'no-cache',
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((res) => {
        if (
          ui.slidingPane.data.hca34.toLowerCase() === loggedInUser.toLowerCase()
        ) {
          dispatch(
            authenticateUser({
              user34: loggedInUser,
              authToken,
              preferredSiteId: siteId,
            }),
          )
        }
        dispatch(
          getAllUsers({
            facilityId: selectedAdminSite ? selectedAdminSite.facilityId : null,
            authToken,
            searchText,
            divisionId,
            clinicalRole: clinicalRole,
            skip,
            take,
            sortBy,
            sortDirection,
          }),
        )
      })
      .catch((err) => {
        console.log(err.response)
        ErrorCheck(err)
      })
  }
export const updateUser =
  ({
    user,
    siteId,
    authToken,
    loggedInUser,
    searchText,
    divisionId,
    skip,
    take,
    sortBy,
    sortDirection,
    selectedAdminSite,
    selectedClinicalRole,
  }) =>
  (dispatch) => {
    const authorization = authToken
    axios({
      method: 'PUT',
      url: serverUrl + `User/${user.id}`,
      data: JSON.stringify(user),
      headers: {
        authorization,
        siteId,
        Pragma: 'no-cache',
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((res) => {
        if (user.hca34.toLowerCase() === loggedInUser.toLowerCase()) {
          dispatch(
            authenticateUser({
              user34: loggedInUser,
              authToken,
              preferredSiteId: siteId,
            }),
          )
        }
        dispatch(
          getAllUsers({
            facilityId: selectedAdminSite ? selectedAdminSite.facilityId : null,
            authToken,
            searchText,
            divisionId,
            clinicalRole: selectedClinicalRole,
            skip,
            take,
            sortBy,
            sortDirection,
          }),
        )
      })
      .catch((err) => {
        console.log(err.response)
        ErrorCheck(err)
      })
  }

export const updateUserRestricted =
  ({
    user,
    siteId,
    authorization,
    loggedInUser,
    searchText,
    divisionId,
    skip,
    take,
    sortBy,
    sortDirection,
    selectedAdminSite,
    selectedClinicalRole,
  }) =>
  (dispatch) => {
    axios({
      method: 'PUT',
      url: serverUrl + `User/Restricted/${user.id}`,
      data: JSON.stringify(user),
      headers: {
        authorization,
        siteId,
        Pragma: 'no-cache',
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((res) => {
        if (user.hca34.toLowerCase() === loggedInUser.toLowerCase()) {
          dispatch(
            authenticateUser({
              user34: loggedInUser,
              authToken: authorization,
              preferredSiteId: siteId,
            }),
          )
        }
        dispatch(
          getAllUsers({
            facilityId: selectedAdminSite ? selectedAdminSite.facilityId : null,
            authToken: authorization,
            searchText,
            divisionId,
            clinicalRole: selectedClinicalRole,
            skip,
            take,
            sortBy,
            sortDirection,
          }),
        )
        dispatch(fetchStaffPool(authorization, siteId))
      })
      .catch((err) => {
        console.log(err.response)
        ErrorCheck(err)
      })
  }

export const postUser =
  ({
    user,
    authorization,
    siteId,
    searchText,
    divisionId,
    skip,
    take,
    sortBy,
    sortDirection,
    selectedAdminSite,
    selectedClinicalRole,
  }) =>
  (dispatch) => {
    axios({
      method: 'GET',
      url: serverUrl + `User/GetUserByHca34/${user.hca34}`,
      headers: {
        authorization,
        siteId,
        Pragma: 'no-cache',
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        //if there is a user that has this 3-4 already
        //dispatch fail message
        dispatch({
          type: 'ui/FLASH_MESSAGE',
          payload: {
            messages: ['A user with that 3-4 already exists.'],
            type: 'failure',
            uuid: uuid(),
          },
        })
      })
      .catch((err) => {
        if (err.response.status === 404) {
          axios({
            method: 'POST',
            url: serverUrl + `User/`,
            data: JSON.stringify(user),
            headers: {
              authorization,
              siteId,
              Pragma: 'no-cache',
              'content-type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
          }).then((res) => {
            //dispatch success message
            dispatch({
              type: 'ui/FLASH_MESSAGE',
              payload: {
                messages: ['User created!'],
                type: 'success',
                uuid: uuid(),
              },
            })
            dispatch(
              getAllUsers({
                facilityId: selectedAdminSite
                  ? selectedAdminSite.facilityId
                  : null,
                authToken: authorization,
                searchText,
                divisionId,
                clinicalRole: selectedClinicalRole,
                skip,
                take,
                sortBy,
                sortDirection,
              }),
            )
            if (res.status === 400) {
              dispatch({
                type: 'ui/FLASH_MESSAGE',
                payload: {
                  messages: ['Error creating user. Try again.'],
                  type: 'failure',
                  uuid: uuid(),
                },
              })
            }
          })
        }
      })
  }
