const initialState = {
  userGroups: [],
  selectedUserGroupId: null,
  fetching: false,
  error: null,
  fetchingClinicalRoles: false,
  clinicalRoles: [],
  clinicalRolesError: '',
  clinicalRolesForAddUser: [],
  clinicalRolesForAddUserError: null,
  fetchingLockedRoles: false,
  lockedRoles: [],
  lockedRolesError: '',
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'metadata/SET_SELECTED_USER_GROUP':
      return { ...state, selectedUserGroupId: action.payload }
    case 'metadata/FETCHING_CLINICAL_ROLES':
      return { ...state, fetchingClinicalRoles: action.payload }
    case 'metadata/FETCHING_CLINICAL_ROLES_RECEIVED':
      return { ...state, clinicalRoles: action.payload }
    case 'metadata/FETCHING_CLINICAL_ROLES_ERROR':
      return { ...state, clinicalRolesError: action.payload }
    case 'metadata/FETCH_USER_GROUPS':
      return { ...state, fetching: true }
    case 'metadata/FETCH_USER_GROUPS_ERROR':
      return { ...state, error: action.payload, fetching: false }
    case 'metadata/FETCH_USER_GROUPS_RECEIVED':
      return { ...state, userGroups: action.payload, fetching: false }
    case 'metadata/CLEAR_ALL_AUTH_ROLES':
      return { ...state, clinicalRolesForAddUser: [] }
    case 'metadata/FETCHING_CLINICAL_ROLES_FOR_ADD_USER_RECEIVED':
      return { ...state, clinicalRolesForAddUser: action.payload }
    case 'metadata/FETCHING_CLINICAL_ROLES_FOR_ADD_USER_ERROR':
      return { ...state, clinicalRolesForAddUserError: action.payload }
    case 'metadata/FETCH_LOCKED_ROLES_REQUEST':
      return { ...state, fetchingLockedRoles: action.payload }
    case 'metadata/FETCH_LOCKED_ROLES_SUCCESS':
      return {
        ...state,
        lockedRoles: action.payload,
        fetchingLockedRoles: false,
      }
    case 'metadata/CLEAR_LOCKED_ROLES':
      return { ...state, lockedRoles: [], fetchingLockedRoles: false }
    case 'metadata/FETCH_LOCKED_ROLES_ERROR':
      return {
        ...state,
        lockedRolesError: action.payload,
        fetchingLockedRoles: false,
      }
    default:
      return state
  }
}
