const ReadOnlyBanner = () => {
  return (
    <div
      className="readOnly neu-text--caption"
      style={{
        backgroundColor: '#EFF8FD',
        textAlign: 'center',
        padding: '10px',
        boxShadow: '1px 2px 5px 1px !important',
      }}
    >
      This unit is currently in “read only” access
    </div>
  )
}

export default ReadOnlyBanner
