/**
 * External Imports
 * */
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'
import { useSelector } from 'react-redux'
import Events from '../../analytics/events'

/**
 * Internal Imports
 * */
import Checkbox from './neutronComponents/checkbox/checkbox.js'

export const UnitDropdown = ({
  showDropdown,
  saveInput,
  searching,
  searchText,
  selectedUnits,
  units,
  isDraft,
  selectItem,
}) => {
  const { logTrackingEvent } = useAnalyticsApi()
  const orgId = useSelector((state) => state.user.userSettings.facilityCoid)

  const selectUnitTracking = () => {
    const ctaLastPath = localStorage.getItem('ctaLastPath')

    if (isDraft && ctaLastPath.includes('locationassignments')) {
      logTrackingEvent([
        {
          module: Events.NewDraftTab.Module,
          screen: Events.NewDraftTab.Screen.Name,
          eventName:
            Events.NewDraftTab.Screen.Events
              .LocationTab_NewDraftUnitDropdownClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (!isDraft && ctaLastPath.includes('locationassignments')) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen.Name,
          eventName:
            Events.Locations.Screen.Events.LocationTab_UnitDropdownMenuClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (!isDraft && ctaLastPath.includes('patientassignments')) {
      logTrackingEvent([
        {
          module: Events.Patients.Module,
          screen: Events.Patients.Screen.Name,
          eventName:
            Events.Patients.Screen.Events.PatientTab_UnitDropdownMenuClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    }
  }

  return (
    <div
      id="unit-dropdown"
      ui="true"
      role="combobox"
      aria-disabled="false"
      className="ui floating search selection dropdown"
      style={{ width: '300px' }}
    >
      <input
        id="unit-input"
        className="search"
        tabIndex="0"
        aria-valuemax="19"
        type="text"
        autoComplete="off"
        style={{
          opacity: '0',
          maxWidth: '224px',
          zIndex: '99',
        }}
        onClick={() => {
          showDropdown()
          selectUnitTracking()
        }}
        onChange={(event) => {
          const sanitizedSearchText = event.target.value.trim()
          function debounce(func, timeout = 2) {
            let timer
            return (...args) => {
              clearTimeout(timer)
              timer = setTimeout(() => {
                func.apply(this, args)
              }, timeout)
            }
          }
          const searchUnitDropdown = debounce(() =>
            saveInput(sanitizedSearchText, 'unit'),
          )

          searchUnitDropdown(sanitizedSearchText)
        }}
      ></input>
      <div
        className="text"
        style={{ maxWidth: '210px' }}
        role="alert"
        aria-live="polite"
        aria-atomic="true"
        onClick={() => {
          showDropdown()
        }}
      >
        {searching
          ? searchText
          : selectedUnits.length > 1
          ? `${selectedUnits.length} Units Selected`
          : selectedUnits[0] && selectedUnits[0].text}
      </div>
      <i
        id="unit-icon"
        className="dropdown icon"
        onClick={() => {
          showDropdown()
        }}
        aria-hidden="true"
      ></i>
      <div id="unit-dropdown-items" role="listbox" className="menu transition">
        {units && isDraft
          ? units
              .filter((unit) => unit.key !== 'all')
              .map((unit) => (
                <div
                  className="item"
                  onClick={() => selectItem(unit)}
                  key={unit.value}
                  href="#"
                >
                  <Checkbox
                    id="unit-item-checkbox"
                    key={unit.value}
                    isSelected={
                      selectedUnits.filter((i) => i.value === unit.value)
                        .length > 0
                    }
                    click={(e) => selectItem(unit)}
                  />
                  <span
                    id={'unit-dropdown-item'}
                    style={{ paddingLeft: '10px' }}
                  >
                    {unit.text}
                  </span>
                </div>
              ))
          : units.map((unit) => (
              <div
                className="item"
                onClick={() => selectItem(unit)}
                key={unit.value}
                href="#"
              >
                <Checkbox
                  id="unit-item-checkbox"
                  key={unit.value}
                  isSelected={
                    selectedUnits.filter((i) => i.value === unit.value).length >
                    0
                  }
                  click={(e) => selectItem(unit)}
                />
                <span id={'unit-dropdown-item'} style={{ paddingLeft: '10px' }}>
                  {unit.text}
                </span>
              </div>
            ))}
        {/* {units && units.map(unit =>
                          <div className='item' onClick={() => selectItem(unit)} key={unit.value} href="#">
                            <Checkbox
                              id='unit-item-checkbox'
                              key={unit.value}
                              isSelected={selectedUnits.filter(i => i.value === unit.value).length > 0}
                              click={e => selectItem(unit)} />
                            <span id={'unit-dropdown-item' } style={{paddingLeft: '10px'}}>
                              {unit.text}
                              </span>
                          </div>
                        )} */}
      </div>
    </div>
  )
}
export const DeptDropdown = ({
  showDropdown,
  saveInput,
  searching,
  searchText,
  selectedDept,
  options,
  selectDept,
  isDraft,
}) => {
  const { logTrackingEvent } = useAnalyticsApi()
  const orgId = useSelector((state) => state.user.userSettings.facilityCoid)

  const selectDeptTracking = () => {
    const ctaLastPath = localStorage.getItem('ctaLastPath')
    if (isDraft && ctaLastPath.includes('locationassignments')) {
      logTrackingEvent([
        {
          module: Events.NewDraftTab.Module,
          screen: Events.NewDraftTab.Screen.Name,
          eventName:
            Events.NewDraftTab.Screen.Events
              .LocationTab_NewDraftDeptDropdownClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (!isDraft && ctaLastPath.includes('locationassignments')) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen.Name,
          eventName:
            Events.Locations.Screen.Events.LocationTab_DeptDropdownMenuClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (ctaLastPath.includes('patientassignments')) {
      logTrackingEvent([
        {
          module: Events.Patients.Module,
          screen: Events.Patients.Screen.Name,
          eventName:
            Events.Patients.Screen.Events.PatientTab_DeptDropdownMenuClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    }
  }

  return (
    <div
      id="dept-dropdown"
      ui="true"
      role="combobox"
      aria-disabled="false"
      className="ui floating search selection dropdown"
      style={{ width: '300px' }}
    >
      <input
        id="dept-input"
        className="search"
        tabIndex="0"
        type="text"
        autoComplete="off"
        style={{ opacity: '0', fontFamily: 'HCA-Mark', zIndex: '99' }}
        onClick={(e) => {
          showDropdown()
          selectDeptTracking()
        }}
        onChange={(event) => {
          const sanitizedSearchText = event.target.value.trim()
          function debounce(func, timeout = 2) {
            let timer
            return (...args) => {
              clearTimeout(timer)
              timer = setTimeout(() => {
                func.apply(this, args)
              }, timeout)
            }
          }
          const searchUnitDropdown = debounce(() =>
            saveInput(sanitizedSearchText, 'dept'),
          )

          searchUnitDropdown(sanitizedSearchText)
        }}
      ></input>
      <div
        className="text"
        role="alert"
        onClick={() => {
          showDropdown()
        }}
      >
        {searching ? searchText : selectedDept.text}
      </div>
      <i
        id="dept-icon"
        className="dropdown icon"
        onClick={() => {
          showDropdown()
        }}
        aria-hidden="true"
      ></i>
      <div id="dept-dropdown-items" role="listbox" className="menu transition">
        {options &&
          options.map((option) => (
            <div
              className="item"
              style={{
                background:
                  selectedDept.value === option.value
                    ? 'rgba(0,0,0,.05)'
                    : 'white',
                overflow: 'wrap',
              }}
              onClick={() => {
                selectDept(option)
              }}
              key={option.value}
              href="#"
            >
              {option.text}
            </div>
          ))}
      </div>
    </div>
  )
}
