/**
 * @description All Events will be written inside this file. Format for this event are as follows
 */

const Events = {
  Locations: {
    Module: 'LocationTab',
    Screen: {
      Name: 'LocationTab',
      Events: {
        LocationTab_Click: 'LocationTab_Click',
        LocationTab_DeptDropdownMenuClick: 'LocationTab_DeptDropdownMenuClick',
        LocationTab_UnitDropdownMenuClick: 'LocationTab_UnitDropdownMenuClick',
        LocationTab_StaffPoolSearchFieldClick: 'LocationTab_StaffPoolSearchFieldClick',
        LocationTab_StaffMemberSelectClick: 'LocationTab_StaffMemberSelectClick',
        LocationTab_UnitPatientSearchFieldClick: 'LocationTab_UnitPatientSearchFieldClick',
        LocationTab_AssignButtonClick: 'LocationTab_AssignButtonClick',
        LocationTab_UnassignButtonClick: 'LocationTab_UnassignButtonClick',
        LocationTab_PublishButtonClick: 'LocationTab_PublishButtonClick',
      },
    },
  },
  NewDraftTab: {
    Module: 'NewDraftTab',
    Screen: {
      Name: 'NewDraftTab',
      Events: {
        LocationTab_NewDraftTabClick: 'LocationTab_NewDraftTabClick',
        LocationTab_NewDraftDeptDropdownClick: 'LocationTab_NewDraftDeptDropdownClick',
        LocationTab_NewDraftUnitDropdownClick: 'LocationTab_NewDraftUnitDropdownClick',
        LocationTab_NewDraftStaffPoolClick: 'LocationTab_NewDraftStaffPoolClick',
        LocationTab_NewDraftStaffMemberClick: 'LocationTab_NewDraftStaffMemberClick',
        LocationTab_NewDraftSearchFieldClick: 'LocationTab_NewDraftSearchFieldClick',
        LocationTab_NewDraftAssignButtonClick: 'LocationTab_NewDraftAssignButtonClick',
        LocationTab_NewDraftUnassignButtonClick: 'LocationTab_NewDraftUnassignButtonClick',
        LocationTab_NewDraftPublishButtonClick: 'LocationTab_NewDraftPublishButtonClick',
      },
    },
  },
  Patients: {
    Module: 'PatientTab',
    Screen: {
      Name: 'PatientTab',
      Events: {
        PatientTab_PatientTabClick: 'PatientTab_Click',
        PatientTab_DeptDropdownMenuClick: 'PatientTab_DeptDropdownMenuClick',
        PatientTab_UnitDropdownMenuClick: 'PatientTab_UnitDropdownMenuClick',
        PatientTab_StaffPoolSearchFieldClick: 'PatientTab_StaffPoolSearchFieldClick',
        PatientTab_StaffMemberSelectClick: 'PatientTab_StaffMemberSelectClick',
        PatientTab_UnitPatientSearchFieldClick: 'PatientTab_UnitPatientSearchFieldClick',
        PatientTab_AssignButtonClick: 'PatientTab_AssignButtonClick',
        PatientTab_UnassignButtonClick: 'PatientTab_UnassignButtonClick',
        PatientTab_PublishButtonClick: 'PatientTab_PublishButtonClick',
      },
    },
  },
  Reports: {
    Module: 'ReportsTab',
    Screen: [
      {
        Name: 'Snapshot',
        Events: {
          Snapshot_ReportClick: 'Snapshot_ReportClick',
          Snapshot_UnitDropdownClick: 'Snapshot_UnitDropdownClick',
          Snapshot_PositionDropdownClick: 'Snapshot_PositionDropdownClick',
          Snapshot_DateFieldClick: 'Snapshot_DateFieldClick',
          Snapshot_TimeHourClick: 'Snapshot_TimeHourClick',
          Snapshot_TimeMinuteClick: 'Snapshot_TimeMinuteClick',
          Snapshot_KeyWordClick: 'Snapshot_KeyWordClick',
          Snapshot_CancelButtonClick: 'Snapshot_CancelButtonClick',
          Snapshot_RunReportClick: 'Snapshot_RunReportClick',
        },
      },
      {
        Name: 'AssignmentAudit',
        Events: {
          AssignmentAudit_ReportClick: 'AssignmentAudit_ReportClick',
          AssignmentAudit_UnitDropdownClick: 'AssignmentAudit_UnitDropdownClick',
          AssignmentAudit_PositionDropdownClick: 'AssignmentAudit_PositionDropdownClick',
          AssignmentAudit_TimeClick: 'AssignmentAudit_TimeClick',
          AssignmentAudit_KeyWordClick: 'AssignmentAudit_KeyWordClick',
          AssignmentAudit_CancelButtonClick: 'AssignmentAudit_CancelButtonClick',
          AssignmentAudit_RunReportClick: 'AssignmentAudit_RunReportClick',
        },
      },
      {
        Name: 'MissingAssignment',
        Events: {
          MissingAssignments_ReportClick: 'MissingAssignments_ReportClick',
          MissingAssignments_UnitDropdownClick: 'MissingAssignments_UnitDropdownClick',
          MissingAssignments_PositionDropdownClick: 'MissingAssignments_PositionDropdownClick',
          MissingAssignments_TimeClick: 'MissingAssignments_TimeClick',
          MissingAssignments_KeyWordClick: 'MissingAssignments_KeyWordClick',
          MissingAssignments_CancelButtonClick: 'MissingAssignments_CancelButtonClick',
          MissingAssignments_RunReportClick: 'MissingAssignments_RunReportClick',
        },
      },
    ],
  },
  Manage: {
    Module: 'ManageTab',
    Screen: [
      {
        Name: 'Users',
        Events: {
          ManageTab_UsersTabClick: 'ManageTab_UsersTabClick',
          ManageTab_DivisionDropdownMenuClick: 'ManageTab_DivisionDropdownMenuClick',
          ManageTab_FacilityDropdownMenuClick: 'ManageTab_FacilityDropdownMenuClick',
          ManageTab_RoleDropdownMenuClick: 'ManageTab_RoleDropdownMenuClick',
          ManageTab_DownloadUserListClick: 'ManageTab_DownloadUserListClick',
        },
      },
      {
        Name: 'Locations',
        Events: {
            ManageTab_LocationsTabClick: 'ManageTab_LocationsTabClick',
            ManageTab_DivisionDropdownMenuClick: 'ManageTab_DivisionDropdownMenuClick',
            ManageTab_FacilityDropdownMenuClick: 'ManageTab_FacilityDropdownMenuClick',
            ManageTab_UnitsDropdownMenuClick: 'ManageTab_UnitsDropdownMenuClick',
            ManageTab_RoomsDropdownMenuClick: 'ManageTab_RoomsDropdownMenuClick',
            ManageTab_StatusDropdownMenuClick: 'ManageTab_StatusDropdownMenuClick',
        },
      },
      {
        Name: 'Patients',
        Events: {
            ManageTab_PatientsTabClick: 'ManageTab_PatientsTabClick',
            ManageTab_DivisionDropdownMenuClick: 'ManageTab_DivisionDropdownMenuClick',
            ManageTab_FacilityDropdownMenuClick: 'ManageTab_FacilityDropdownMenuClick',
            ManageTab_UnitsDropdownMenuClick: 'ManageTab_UnitsDropdownMenuClick',
            ManageTab_SearchFieldClick: 'ManageTab_SearchFieldClick',
            ManageTab_RemoveButtonClick: 'ManageTab_RemoveButtonClick',
        },
      },
    ],
  },
}
export default Events;

