/**
 * Internal Imports
 * */
import Flex from './styleLibrary'
import IsolationStatusBadge from '../table/IsolationStatusBadge'
/**
 * Styling Imports
 * */
import { Chip, Bubble } from './neutronComponents/chip/chip'

const PrintLocations = (data, props) => {
  const disabledRoles = props.disabled

  const locationChipSlots = props.props.staffSlots.map((slot) => {
    const assignment = data[slot.systemName]
    const isDisabled = disabledRoles.some((chip) => chip === slot.systemName)

    const stagedStaffSlot =
      props.stagedStaffSlots &&
      props.stagedStaffSlots.length > 0 &&
      props.stagedStaffSlots.filter(
        (slot) =>
          assignment.systemName === slot.manifest.AssignmentSlot.SystemName &&
          slot.uuid === data.uuid,
      )[0]

    const assignedUser = stagedStaffSlot
      ? stagedStaffSlot &&
        stagedStaffSlot.assignedToLocation &&
        stagedStaffSlot &&
        stagedStaffSlot.assignedToLocation.user
      : assignment &&
        assignment.assignedToLocation &&
        assignment &&
        assignment.assignedToLocation.user

    const currentRowFromState = props.list.filter((row) => {
      // if list has a user assigned to this role
      return row.uuid === data.uuid
    })[0]

    const currentUserFromState =
      currentRowFromState[slot.systemName] &&
      currentRowFromState[slot.systemName].assignedToLocation &&
      currentRowFromState[slot.systemName].assignedToLocation.user

    const isFreshAssignment = !(
      currentUserFromState && currentUserFromState.length > 0
    )

    if (props.ui.isDraftAssignment) {
      return (
        <Chip
          style={{
            paddingRight: '100px',
            background: 'pink',
          }}
          action={
            (assignment &&
              assignment.assignedToLocation &&
              assignment &&
              assignment.assignedToLocation.user) ||
            assignedUser
              ? 'assign'
              : false
          }
          staged={stagedStaffSlot}
          isFreshAssignment={true}
          assignedUser={assignedUser}
          assignmentText={
            (assignedUser && assignedUser) ||
            (assignment && assignment.abbreviation)
          }
          roleText={assignment.abbreviation}
          selected={
            props.selectedStaffSlots.length > 0 &&
            props.selectedStaffSlots.filter(
              (chip) =>
                chip.manifest.AssignmentSlot &&
                chip.manifest.AssignmentSlot.SystemName ===
                  (assignment && assignment.systemName) &&
                chip.uuid === data.uuid,
            ).length > 0
          }
          isDisabled={isDisabled}
          click={() => {
            props.selectChip({ data, assignment })
          }}
          context={data.rowIndex === props.list.length - 1 ? 'last' : ''}
        />
      )
    } else {
      return (
        <Chip
          style={{
            paddingRight: '100px',
            background: 'pink',
          }}
          action={stagedStaffSlot && stagedStaffSlot.action}
          staged={stagedStaffSlot}
          isFreshAssignment={isFreshAssignment}
          assignedUser={assignedUser}
          assignmentText={
            (assignedUser && assignedUser) ||
            (assignment && assignment.abbreviation)
          }
          roleText={assignment.abbreviation}
          selected={
            props.selectedStaffSlots.length > 0 &&
            props.selectedStaffSlots.filter(
              (chip) =>
                chip.manifest.AssignmentSlot &&
                chip.manifest.AssignmentSlot.SystemName ===
                  (assignment && assignment.systemName) &&
                chip.uuid === data.uuid,
            ).length > 0
          }
          isDisabled={isDisabled}
          click={() => {
            props.selectChip({ data, assignment })
          }}
          context={data.rowIndex === props.list.length - 1 ? 'last' : ''}
        />
      )
    }
  })
  const patientAssignmentBubbles = []
  props.staffSlots.forEach((_slot) => {
    if (
      data[_slot.systemName] &&
      data[_slot.systemName].assignedToPatient.user
    ) {
      patientAssignmentBubbles.push(data[_slot.systemName].abbreviation)
    } else {
      patientAssignmentBubbles.push('___')
    }
  })

  const formattedData = [
    <Flex
      style={{
        fontWeight: 'bold',
        fontFamily: 'HCA-Mark-Bold',
        width: '140px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginTop: '10px',
        top: '50%',
      }}
      className="neu-text--body neu-text--bold neu-text--gray90"
    >
      {data.location.bed}
    </Flex>,
    <Flex
      style={{
        fontSize: 12,
        width: '272px',
        color: '#1F2532',
        fontWeight: '500',
        overflow: 'hidden',
      }}
      direction="column"
    >
      <span
        style={{
          width: '170px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {data.patient.name}
      </span>
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <Flex>
          {data.patient.meta}
          <IsolationStatusBadge
            isolationStatusList={data.patient.isolationStatus}
            keyProp={data.patient.id}
          />
        </Flex>
      </span>
    </Flex>,
    <Flex style={{ marginTop: 20 }}>
      {props.staffSlots.map((_slot, index) => {
        return (
          <Bubble
            text={patientAssignmentBubbles[index]}
            key={data.uuid + index}
          />
        )
      })}
    </Flex>,
  ]
  for (let i = 0; i < locationChipSlots.length; i++) {
    const itemIndex = 2 + i
    formattedData.splice(itemIndex, 0, locationChipSlots[i])
  }
  return formattedData
}

export default PrintLocations
