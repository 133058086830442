/**
 * External Imports
 * */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'

/**
 * Internal Imports
 * */
import Flex from '../reusableComponents/styleLibrary'
import Events from '../../analytics/events'
import _ from 'lodash'

export const MultiSelectDropdown = ({
  selections,
  options,
  updateDropdownList,
  id,
  orgId,
}) => {
  const [showHide, setShowHide] = useState(false)
  const [optionsListTop, setOptionsListTop] = useState(50)
  const mainRef = useRef()

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      const { height } = mainRef.current.getBoundingClientRect() || 50
      setOptionsListTop(height + 10)
      window.addEventListener('click', (e) => {
        if (
          e.target.id === id + ':multiSelectCaret' ||
          e.target.id === id + ':multiselectChild'
        ) {
          setShowHide(!showHide)
        } else if (
          e.target.id !== id + ':multiselectChild' ||
          e.target.id !== id + ':multiSelectCaret'
        ) {
          setShowHide(false)
        }
      })
    }
    return () => (isMounted = false)
  }, [selections, showHide])

  /**
   * Logs tracking events based on the unit and position dropdown clicks
   */

  const { logTrackingEvent } = useAnalyticsApi()

  const trackingReportClicks = () => {
    const ctaLastPath = localStorage.getItem('ctaLastPath')

    const logReportEvent = (screenIndex, eventName) => {
      logTrackingEvent([
        {
          module: Events.Reports.Module,
          screen: Events.Reports.Screen[screenIndex].Name,
          eventName,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    }

    if (ctaLastPath.includes('reports/snapshot') && id === 'unit') {
      logReportEvent(
        0,
        Events.Reports.Screen[0].Events.Snapshot_UnitDropdownClick,
      )
    } else if (
      ctaLastPath.includes('reports/missingassignments') &&
      id === 'unit'
    ) {
      logReportEvent(
        2,
        Events.Reports.Screen[2].Events.MissingAssignments_UnitDropdownClick,
      )
    } else if (
      ctaLastPath.includes('reports/assignmentaudit') &&
      id === 'unit'
    ) {
      logReportEvent(
        1,
        Events.Reports.Screen[1].Events.AssignmentAudit_UnitDropdownClick,
      )
    } else {
      // no logging event
    }

    if (ctaLastPath.includes('reports/snapshot') && id === 'position') {
      logReportEvent(
        0,
        Events.Reports.Screen[0].Events.Snapshot_PositionDropdownClick,
      )
    } else if (
      ctaLastPath.includes('reports/missingassignments') &&
      id === 'position'
    ) {
      logReportEvent(
        2,
        Events.Reports.Screen[2].Events
          .MissingAssignments_PositionDropdownClick,
      )
    } else if (
      ctaLastPath.includes('reports/assignmentaudit') &&
      id === 'position'
    ) {
      logReportEvent(
        1,
        Events.Reports.Screen[1].Events.AssignmentAudit_PositionDropdownClick,
      )
    } else {
      // no logging event
    }
  }

  return (
    <div>
      <Flex
        id="multiselectParent"
        direction="row"
        style={{
          width: '390px',
          position: 'relative',
          border: '1px solid #ddd',
          marginBottom: 10,
          marginTop: '10px',
          borderRadius: 5,
          padding: '5px 15px',
          minHeight: '42px',
        }}
        ref={mainRef}
      >
        <Flex
          style={{
            flex: '1 1 150px',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: '#FFF',
            width: '170px',
            flexWrap: 'wrap',
          }}
          onClick={() => {
            trackingReportClicks()
          }}
          id={id + ':multiselectChild'}
        >
          {selections.map((p, index) => (
            <Flex
              key={p.text + index}
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                background: '#D9D9D6',
                color: '#1F2532',
                margin: '2.5px 5px',
                padding: '0 15px',
                maxWidth: '100%',
                textAlign: 'center',
                borderRadius: '25px',
                border: `3px solid #D9D9D6`,
              }}
            >
              {p.text}
              <i
                className="material-icons"
                style={{
                  marginLeft: 5,
                  color: '#898B8E',
                  cursor: 'pointer',
                  fontSize: '13px',
                  fontWeight: 'bold',
                  // padding: '0px 15px',
                }}
                onClick={() => {
                  updateDropdownList([
                    ...selections.filter(
                      (someOption) => p.text !== someOption.text,
                    ),
                  ])
                }}
              >
                remove_circle_outline
              </i>
            </Flex>
          ))}
        </Flex>
        {showHide && options.length > 0 && (
          <div
            style={{
              top: optionsListTop,
              left: 0,
              width: '100%',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '5px',
              height: 'max-content',
              maxHeight: 200,
              overflowY: 'scroll',
              zIndex: 99,
              boxShadow: '1px 2px 5px 1px rgba(0, 0, 0, 0.21)',
            }}
          >
            {options &&
              options.map((option, index) => {
                return (
                  <div
                    key={option.text + index}
                    style={{
                      padding: '10px',
                      cursor: 'pointer',
                      fontFamily: 'sans-serif',
                      boxSizing: 'border-box',
                      textAlign: 'left',
                      borderBottom: '0.5px solid rgba(0, 0, 0, 0.3)',
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (
                        selections.some(
                          (selection) => selection.text === 'All',
                        ) &&
                        option.text !== 'All'
                      ) {
                        updateDropdownList([option])
                      } else if (
                        selections.some(
                          (selection) => selection.text !== 'All',
                        ) &&
                        option.text === 'All'
                      ) {
                        updateDropdownList([option])
                      } else if (
                        selections.some(
                          (selection) => selection.text === option.text,
                        )
                      ) {
                        return
                      } else {
                        updateDropdownList([...selections, option])
                        return
                      }
                    }}
                  >
                    {option.text}
                  </div>
                )
              })}
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            right: 0,
            marginTop: '2px',
            cursor: 'pointer',
          }}
        >
          <i className="material-icons" id={id + ':multiSelectCaret'}>
            {!showHide || options.length === 0
              ? 'arrow_drop_down'
              : 'arrow_drop_up'}
          </i>
        </div>
      </Flex>
    </div>
  )
}
